import { Route, RouteProps } from 'react-router-dom';
import React, { Suspense, SuspenseProps, useMemo } from 'react';

export type LazyRouteProps = Omit<RouteProps, 'children'> & {
  fallback?: SuspenseProps['fallback'];
  children?: SuspenseProps['children'];
  componentProps?: Record<string, unknown>;
};

export default function LazyRoute({
  fallback = null,
  children,
  component,
  componentProps,
  ...routeProps
}: LazyRouteProps) {
  const lazyChildren = useMemo(() => {
    if (component) {
      const Component = component as any;

      return <Component {...componentProps} />;
    }

    return children;
  }, [children, component]);

  return (
    <Route {...routeProps}>
      <Suspense fallback={fallback as SuspenseProps['fallback']}>
        {lazyChildren}
      </Suspense>
    </Route>
  );
}