/* eslint-disable */
import React, { ReactElement } from 'react';
import { Button } from '@vimily/bonjoro-ui-components-v2/Button';

import { createStyledComponent } from '~/App/Components/HOC/Styleable';
import { StyledComponent } from '@emotion/styled';

export const styles = () => ({});

const BaseButton = ({ getStyles, ...props }) => (
  <Button variant='secondary' {...props} />
);

const CancelButton = createStyledComponent(
  BaseButton as unknown as StyledComponent<React.ComponentType, ReactElement>,
  'cancelButton'
);

export default CancelButton;
