import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  QueryClient as TanstackQueryClient,
  QueryClientProvider as TanstackQueryClientProvider,
} from '@tanstack/react-query';

export interface QueryClientWrapperProps {
  clientArgs: Record<string, any>;
  children: React.ReactNode;
}

export function QueryClientWrapper({
  clientArgs,
  children
}: QueryClientWrapperProps) {

  const queryClient = new QueryClient(clientArgs);
  const tanstackQueryClient = new TanstackQueryClient(clientArgs);

  return (
    <TanstackQueryClientProvider client={tanstackQueryClient}>
      <QueryClientProvider client={queryClient}>
        {children}
      </QueryClientProvider>
    </TanstackQueryClientProvider>
  );
}
