import { DropdownPanel } from '~/App/Components/DropdownPanel';
import { createStyledComponent } from '~/App/Components/HOC/Styleable';

export const styles = () => ({
  width: '100%',
  padding: '40px',
  borderRadius: '20px',
});

export default createStyledComponent(DropdownPanel, 'panel');
