/* eslint-disable */
import React, { ReactElement } from 'react';
import { Button } from '@vimily/bonjoro-ui-components-v2/Button';

import { createStyledComponent } from '~/App/Components/HOC/Styleable';
import { StyledComponent } from '@emotion/styled';

export const styles = () => ({
  marginRight: '5px',
});

const BaseButton = ({ getStyles, ...props }) => (
  <Button variant='primary' {...props} />
);

const ConfirmButton = createStyledComponent(
  BaseButton as unknown as StyledComponent<React.ComponentType, ReactElement>,
  'confirmButton'
);

export default ConfirmButton;
