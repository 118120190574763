/* eslint-disable */
import { flow } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, ReactElement } from 'react';

import CancelButton from './CancelButton';
import ConfirmButton from './ConfirmButton';
import { createStyledComponent } from '~/App/Components/HOC/Styleable';
import Replaceable from '~/components/higher-order/ReplaceableComponents';
import { StyledComponent } from '@emotion/styled';

export const styles = () => ({});

export const components = {
  Container: 'div',
  ConfirmButton,
  CancelButton,
};

function Buttons(props) {
  const {
    isLoading,
    onConfirmClick,
    onRequestClose,
    confirmButtonText = 'Ok',
    confirmButtonStyle,
    confirmButtonDisabled,
    cancelButtonText = 'Cancel',
    cancelButtonStyle,
    getStyles,
  } = props;

  const { ConfirmButton, CancelButton } = props.components;

  if (onConfirmClick == null) {
    return (
      <ConfirmButton
        onClick={onRequestClose}
        getStyles={getStyles}
        bsStyle={confirmButtonStyle}
        disabled={confirmButtonDisabled || false}
      >
        {confirmButtonText}
      </ConfirmButton>
    );
  }

  return (
    <React.Fragment>
      <ConfirmButton
        loading={isLoading}
        getStyles={getStyles}
        onClick={onConfirmClick}
        bsStyle={confirmButtonStyle}
        disabled={confirmButtonDisabled || false}
      >
        {confirmButtonText}
      </ConfirmButton>
      <CancelButton
        getStyles={getStyles}
        onClick={onRequestClose}
        bsStyle={cancelButtonStyle}
      >
        {cancelButtonText}
      </CancelButton>
    </React.Fragment>
  );
}

Buttons.propTypes = {
  isLoading: PropTypes.bool,
  onConfirmClick: PropTypes.func,
  onRequestClose: PropTypes.func,
  confirmButtonText: PropTypes.any,
  confirmButtonStyle: PropTypes.string,
  confirmButtonDisabled: PropTypes.bool,
  cancelButtonText: PropTypes.any,
  cancelButtonStyle: PropTypes.string,
  getStyles: PropTypes.func,
};

function Actions(props) {
  const { className, components } = props;

  const { Container } = components;

  return (
    <Container className={className}>
      <Buttons {...props} />
    </Container>
  );
}

Actions.propTypes = {
  className: PropTypes.string,
  components: PropTypes.object,
  onConfirmClick: PropTypes.func,
  isLoading: PropTypes.bool,
  confirmButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
};

export default flow(
  memo,
  Replaceable(components)
)(
  createStyledComponent(
    Actions as unknown as StyledComponent<React.ComponentType, ReactElement>,
    'actions'
  )
);
